<template>
  <main>
    <package-header title="Submit" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">

          <!-- Create package form -->
          <form>
            <div class="form-group">
              <label for="name" class="">Submission Time</label>
              <div class="radio-btn mb-1">
                <input id="radio1" name="radio" type="radio" class="radio" checked>
                <label for="radio1">
                  Submit immediately
                </label>
              </div>

              <div class="d-flex align-items-start">
                <div class="radio-btn">
                  <input id="radio2" name="radio" type="radio" class="radio" disabled>
                  <label for="radio2">
                    Defer until
                  </label>
                </div>

                <div class="form-group position-relative">
                  <div class="position-relative">
                    <input type="text" class="form-control" id="corpDate" name="corpDate" disabled>
                    <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                  </div>
                  <small id="date" class="form-text text-muted">YYYY/MM/DD</small>
                </div>

                <a class="collapse-toggle d-block mt-1 ml-2" href="#deferHelp" data-toggle="collapse" aria-expanded="false" aria-controls="deferHelp">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                  Coming Soon
                </a>
              </div>

              <div class="collapse" id="deferHelp">
                <div class="card card-body bg-light mt-2">
                  <strong>This is an example of a help text collapse.</strong>
                </div>
              </div>

            </div>

            <div class="form-group">
              <label>Notice of Registration</label>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="noticeOfRegistration" checked>
                <label class="custom-control-label" for="noticeOfRegistration">Receive Notice of Registration</label>
              </div>
            </div>

            <button class="btn btn-primary mt-2">Submit</button>
          </form>
        </div>

        <!-- Fee Summary -->
        <div class="col-md-4">
          <div class="card bg-medium border-0 sticky-top">
            <div class="card-body">
              <h3 class="card-title">Fee Summary</h3>

              <dl class="mt-2">
                <dt>File Reference</dt>
                <dd>12764 Smith</dd>
              </dl>

              <p><strong>3 Items</strong></p>

              <div class="d-flex justify-content-between">
                <span>Product Fee:</span>
                <span>$59.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Service Charge:</span>
                <span>$3.00</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <span>Subtotal:</span>
                <span>$62.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>GST:</span>
                <span>$0.29</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <strong>Total</strong>
                <strong>$63.05</strong>
              </div>

              <dl class="mt-2">
                <dt>GST Registration Number</dt>
                <dd>81630 4414 RT0001</dd>
              </dl>

              <router-link to="/pages/fee-details">Review fee details</router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Create Package"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'help-panel': HelpPanel,
  },

  data () {
    return {

    }
  }
}
</script>

